import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/layout"
import client from '../components/utils/client'

import icLogo from "../components/assets/img/pages/produtos/ic-logo.png";

import Banner from "./common/Banner";
import FirstSlider from "./sobre/FirstSlider";
import Grid from "./sobre/Grid";

const AboutPage = () => {

  const [data, setData] = useState([]);  

  useEffect(() => {
    const handleGetData = () => {
      client()
        .get("content-group/10")
        .then(res => {
          let data = res.data || [];
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          setData(data);
        })
        .catch(err =>{
          console.log(err);
        })
    }        

    handleGetData();
  }, []);

  const Historia = () => {
    let allData;
    if(data.Contents) {
      let label = data.Contents.find(item => item.Key === "sobre-main-label");
      let title = data.Contents.find(item => item.Key === "sobre-main-title");
      let paragraphs = data.Contents.filter(item => item.Key.includes("sobre-main-paragraph"));
      let image = data.Contents.find(item => item.Key === "sobre-main-image");
      allData = {
        label: label ? label.Value : "Undefined",
        title: title ? title.Value : "Undefined",
        paragraphs: paragraphs ? paragraphs : null,        
        image: image ? image.Value : icLogo,
      }
      console.log(allData);      
    }

    return (
      <section id="historia" className="container-60">
        {allData ? (
          <>
            <div>
              <div className="def__label__box">
                <div className="rectangle"></div>
                <p className="label">{allData.label}</p>
              </div>
              <h4 className="primary__info__title">{allData.title}</h4>
              {allData.paragraphs ? allData.paragraphs.map((item, index) => (
                <p key={index} className="primary__text">{item.Value}</p>
              )) : null}          
            </div>
            <img src={allData.image} className="img" alt="História" />
          </>
        ) : null}
      </section>
    )
  }

  const VideoInstitucional = () => {
    let allData; 
    if(data.Contents) {
      let label = data.Contents.find(item => item.Key === "sobre-institucional-label");
      let video = data.Contents.find(item => item.Key === "sobre-institucional-video");
      allData = {
        label: label ? label.Value : "Undefined",
        video: video ? video.Value : icLogo,      
      }
    }
    return (
      <section className="video__container container-60">
        {allData ? (
          <>
            <div className="def__label__box">
              <div className="rectangle"></div>
              <p className="label">{allData.label}</p>
              <div className="rectangle"></div>              
            </div>
            <img src={allData.video} alt="Institucional" id="video"/>
          </>
        ) : null}
      </section>
    )
  }

  return (
    <Layout infoPage={{page: "sobre"}}>
      <div className="about">
        <Banner text={"Sobre"} />
        <Historia />
        <Grid data={data} />        
        <FirstSlider data={data} />        
        <VideoInstitucional />
      </div>
    </Layout>
  ) 
}
export default AboutPage;